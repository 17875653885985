import { useEffect, useState } from "react";
import useDataUpdate from "../../function/useDataUpdate";

const DetailArticle = ({ zoneId, rubriqueId, articleId }) => {

    const [zoneData, setZoneData] = useState([]);

    useEffect(() => {
        const traiteZone = localStorage.getItem("tabTraiteZone");
        const parsedData = JSON.parse(traiteZone);

        setZoneData(parsedData[zoneId]["article"]);
    }, [])

    const listArticles = useDataUpdate(zoneData);

    const articles = listArticles.filter((element) => (element.rubriqueId == rubriqueId && element.id == articleId))

    return (
        <>
            {
                articles.map((article) => (
                    <>
                        <div class="module-content module-search-warp">
                            <div class="pos-vertical-center">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                                            <form class="form-search">
                                                <input class="form-control" type="text" placeholder="type words then enter" />
                                                <button></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div><a class="module-cancel" href="#"><i class="fas fa-times"></i></a>
                        </div>
                        <section class="page-title page-title-blank-2 bg-white" id="page-title">
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <h1 class="d-none">{article.titre}</h1>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="breadcrumb-wrap">
                                            <ol class="breadcrumb">
                                                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                                <li class="breadcrumb-item"><a href="blog-grid.html">blog</a></li>
                                                <li class="breadcrumb-item"><a href="services-solar.html">solar modules</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">{article.titre}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="blog blog-single" id="blog">
                            <div class="container">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-8">
                                        <div class="blog-entry">
                                            <div class="entry-img"><img src="assets/images/blog/single/1.jpg" alt="entry image" />
                                                <div class="entry-meta">
                                                    <div class="entry-category"><a href="blog-grid.html">energy</a><a href="blog-grid.html">systems</a></div>
                                                    <div class="entry-date"> <span class="date">22 jan</span><span class="year">2021</span></div>
                                                    <div class="entry-author"><a href="blog-grid.html">karl barry</a></div>
                                                    <div class="entry-comments"><span>comments:</span><span class="num">2</span></div>
                                                </div>
                                            </div>
                                            <div class="entry-content">
                                                <div class="entry-title">
                                                    <h4>{article.titre}</h4>
                                                </div>
                                                <div class="entry-bio">
                                                    <p>{article.contenuArticle}</p>
                                                </div>
                                                <div class="entry-holder">
                                                    <div class="entry-tags"><span>tags: </span><a href="blog-grid.html">solar</a><a href="blog-grid.html">insights</a><a href="blog-grid.html">systems</a><a href="blog-grid.html">battery</a><a href="blog-grid.html">research</a><a href="blog-grid.html">energy</a></div>
                                                    <div class="entry-share"><span>share this article</span>
                                                        <div><a class="share-facebook" href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a><a class="share-instagram" href="javascript:void(0)"><i class="fab fa-twitter"></i></a><a class="share-twitter" href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="nav-posts">
                                                <div class="prev-post">
                                                    <div class="post-img">
                                                        <div class="overlay"> <i class="energia-arrow-right"></i></div><img src="assets/images/blog/thumb/prev.png" alt="title" />
                                                    </div>
                                                    <div class="post-body"><span>previous post</span><a class="post-link" href="blog-single.html">energy research will help eagles coexist wind</a></div>
                                                </div>
                                                <div class="next-post">
                                                    <div class="post-body"><span>next post</span><a class="post-link" href="blog-single.html">the middle east's top new alternative energy source</a></div>
                                                    <div class="post-img">
                                                        <div class="overlay"> <i class="energia-arrow-right"></i></div><img src="assets/images/blog/thumb/next.png" alt="title" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entry-widget-bio">
                                                <div class="entry-widget-content"><img src="assets/images/blog/author/2.jpg" alt="author" />
                                                    <div class="entry-bio-desc">
                                                        <h4>Mahmoud Baghagho</h4>
                                                        <p>Founded by Begha over many cups of tea at her kitchen table in 2009, our brand promise is simple: to provide powerful digital marketing solutions.</p><a href="#"><i class="fab fa-facebook-f"></i></a><a href="#"><i class="fab fa-instagram"></i></a><a href="#"><i class="fab fa-twitter"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entry-widget entry-comments" id="comments">
                                                <div class="entry-widget-title">
                                                    <h4><span class="comments-number">2</span> comments</h4>
                                                </div>
                                                <div class="entry-widget-content">
                                                    <ul class="comments-list">
                                                        <li class="comment-body">
                                                            <div class="avatar"><img src="assets/images/blog/author/1.png" alt="avatar" /></div>
                                                            <div class="comment">
                                                                <h6>Richard Muldoone</h6>
                                                                <div class="date">Feb 28, 2019 - 08:07 pm</div>
                                                                <p>The example about the mattress sizing page you mentioned in the last WBF can be a perfect example of new keywords and content, and broadening the funnel as well. I can only imagine the sale numbers if that was the site of a mattress selling company.</p><a class="reply" href="#comments">reply</a>
                                                                <ul class="replies-list">
                                                                    <li class="comment-body">
                                                                        <div class="avatar"><img src="assets/images/blog/author/2.png" alt="avatar" /></div>
                                                                        <div class="comment">
                                                                            <h6>mike dooley</h6>
                                                                            <div class="date">Feb 28, 2019 - 08:22 pm</div>
                                                                            <p>The example about the mattress sizing page you mentioned in the last WBF can be a perfect example of new keywords and content, and broadening the funnel as well. I can only imagine the sale numbers if that was the site of a selling company.</p><a class="reply" href="#comments">reply</a>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="entry-widget entry-add-comment mb-0 clearfix">
                                                <div class="entry-widget-title">
                                                    <h4>Leave A Reply</h4>
                                                </div>
                                                <div class="entry-widget-content">
                                                    <form class="mb-0" id="post-comment">
                                                        <div class="row">
                                                            <div class="col-12 col-lg-4">
                                                                <input class="form-control" type="text" placeholder="Name" />
                                                            </div>
                                                            <div class="col-12 col-lg-4">
                                                                <input class="form-control" type="email" placeholder="Email" />
                                                            </div>
                                                            <div class="col-12 col-lg-4">
                                                                <input class="form-control" type="text" placeholder="Website" />
                                                            </div>
                                                            <div class="col-12">
                                                                <textarea class="form-control" rows="2" placeholder="Comment"></textarea>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="custom-radio-group">
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                        <input class="custom-control-input" type="radio" id="customRadioInline1" name="customRadioInline1" />
                                                                        <label for="customRadioInline1">Save my name, email, and website in this browser for the next time I comment.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <button class="btn btn--primary btn-line btn-line-before" type="submit"><span>submit comment</span><i class="energia-arrow-right"></i></button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <div class="sidebar sidebar-blog">
                                            <div class="widget widget-search">
                                                <div class="widget-title">
                                                    <h5>search</h5>
                                                </div>
                                                <div class="widget-content">
                                                    <form class="form-search">
                                                        <div class="input-group">
                                                            <input class="form-control" type="text" placeholder="Search ..." /><span class="input-group-btn">
                                                                <button class="btn" type="button"><i class="energia-search-Icon"></i></button></span>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="widget widget-recent-posts">
                                                <div class="widget-title">
                                                    <h5>recent posts</h5>
                                                </div>
                                                <div class="widget-content">
                                                    <div class="post">
                                                        <div class="post-img"><img src="assets/images/blog/sidebar/post-1.jpg" alt="post img" /></div>
                                                        <div class="post-content">
                                                            <div class="post-date"><span class="date">jan 25</span><span class="year">2021</span></div>
                                                            <div class="post-title"><a href="blog-single.html">filling solar power permits in 2020? consider following </a></div>
                                                        </div>
                                                    </div>
                                                    <div class="post">
                                                        <div class="post-img"><img src="assets/images/blog/sidebar/post-2.jpg" alt="post img" /></div>
                                                        <div class="post-content">
                                                            <div class="post-date"><span class="date">jan 27</span><span class="year">2021</span></div>
                                                            <div class="post-title"><a href="blog-single.html">How to Add Battery Backup to an Existing Grid Tied</a></div>
                                                        </div>
                                                    </div>
                                                    <div class="post">
                                                        <div class="post-img"><img src="assets/images/blog/sidebar/post-3.png" alt="post img" /></div>
                                                        <div class="post-content">
                                                            <div class="post-date"><span class="date">jan 23</span><span class="year">2021</span></div>
                                                            <div class="post-title"><a href="blog-single.html">Energy Research Will Help Eagles Coexist Wind</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="widget widget-categories">
                                                <div class="widget-title">
                                                    <h5>categories</h5>
                                                </div>
                                                <div class="widget-content">
                                                    <ul class="list-unstyled">
                                                        <li><a href="blog-grid.html">Wind Turbines</a><span>9</span></li>
                                                        <li><a href="blog-grid.html">Solar Panels</a><span>2</span></li>
                                                        <li><a href="blog-grid.html">Battery Matrial</a><span>5</span></li>
                                                        <li><a href="blog-grid.html">Hydro Plants</a><span>1</span></li>
                                                        <li><a href="blog-grid.html">Fossil Resourc</a><span>7</span></li>
                                                        <li><a href="blog-grid.html">Charge Control</a><span>10</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="widget widget-tags">
                                                <div class="widget-title">
                                                    <h5>Tags</h5>
                                                </div>
                                                <div class="widget-content"><a href="blog-grid.html">Battery</a><a href="blog-grid.html">Hydro</a><a href="blog-grid.html">infectious</a><a href="blog-grid.html">Plants</a><a href="blog-grid.html">Solar</a><a href="blog-grid.html">Turbines</a><a href="blog-grid.html">Fossil</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                ))
            }
        </>
    )
}

export default DetailArticle;