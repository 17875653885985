const staticPart = '0c';

function articleIdEncrypt(id, rid, aid) {
    // Fonction pour générer une chaîne aléatoire de longueur donnée
    const generateRandomString = (length) => {
        const characters = 'abdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
        let randomString = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    };

    // Générer la chaîne aléatoire de 5 caractères
    const randomPart = generateRandomString(4);
    const secondPart = generateRandomString(4);
    const thirdPart = generateRandomString(4);

    // Concaténer la partie statique, la partie aléatoire, et l'ID
    const combinedString = `${randomPart}${staticPart}${id}${staticPart}${rid}${staticPart}${secondPart}${staticPart}${aid}${staticPart}${thirdPart}`;

    return combinedString;
}

export default articleIdEncrypt;