import { Link } from "react-router-dom";
import useDataUpdate from "../../function/useDataUpdate";

const NavBar = ({ menus, infoAbonne }) => {

    const menu = useDataUpdate(menus);
    const info = useDataUpdate(infoAbonne)

    return (
        <nav className="navbar navbar-expand-lg navbar-sticky" id="primary-menu">
            {
                info.map((data) => (
                    <Link className="navbar-brand" to={"https://" + data.adresseWeb} ><img className="logo logo-dark" src={`https://chevea.coo.tg/upload/abonne/${data.urlImage}`} alt={data.nom} /><img className="logo logo-mobile" src={`https://chevea.coo.tg/upload/abonne/${data.urlImage}`} alt={data.nom} /></Link>
                ))
            }
            <div className="collapse navbar-collapse" id="navbarContent">
                <ul className="navbar-nav me-auto">
                    {
                        menu.map((element) => (
                            <li key={element.id} className="nav-item " id={element.id} data-hover=""><Link to={element.lien} ><span>{element.titre}</span></Link>
                            </li>
                        ))
                    }
                    {/* <li className="nav-item has-dropdown active" data-hover=""><a className="dropdown-toggle" href="#" data-toggle="dropdown"><span>Home</span></a>
                        <ul className="dropdown-menu">
                            <li className="nav-item"><a href="index.html"><span>Home main</span></a></li>
                            <li className="nav-item current"><a href="home-modern.html"><span>home modern</span></a></li>
                            <li className="nav-item"><a href="home-classic.html"><span>home classic</span></a></li>
                        </ul>
                    </li>
                    <li className="nav-item" id="contact" data-hover=""><a href="page-contact.html"><span>contact</span></a></li> */}
                </ul>
                {/* <div className="module-holder">
                    <div className="module-call"><i className="icons-energiaphone-call"> </i>
                        <div>
                            <p>call us now:</p><a href="tel:01061245741">+2 01061245741</a>
                        </div>
                    </div>
                    <div className="module module-search">

                    </div>

                    <div className="module module-cart">

                    </div>
                </div> */}
            </div>
        </nav>
    );
}

export default NavBar;