import { useEffect, useState } from "react";
import useDataUpdate from "../../function/useDataUpdate";

const RBanniere1 = ({ id }) => {
    const [zoneData, setZoneData] = useState([]);

    useEffect(() => {
        const traiteZone = localStorage.getItem("tabTraiteZone");
        const parsedData = JSON.parse(traiteZone);

        setZoneData(parsedData[id]["article"]);
    }, [])

    const listArticles = useDataUpdate(zoneData);

    return (
        <>
            <div className="module-content module-search-warp">
                <div className="pos-vertical-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                                <form className="form-search">
                                    <input className="form-control" type="text" placeholder="type words then enter" />
                                    <button></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div><a className="module-cancel" href="#"><i className="fas fa-times"></i></a>
            </div>
            <section className="page-title page-title-1" id="page-title">
                <div className="page-title-wrap bg-overlay bg-overlay-dark-2 bg-section" style={{ backgroundImage: `url('https://chevea.coo.tg/article/WhatsApp Image 2023-11-29 at 2.56.06 PM.jpeg')` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <div className="title">
                                    <h1 className="title-heading">About Us</h1>
                                    <p className="title-desc">{listArticles[id]}</p>
                                    <div className="title-action"> <a className="btn btn--primary" href="page-services.html"> <span>our services</span><i className="energia-arrow-right"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-wrap">
                    <div className="container">
                        <ol className="breadcrumb d-flex">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item"><a href="">company</a></li>
                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RBanniere1;