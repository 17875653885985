import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import articleIdEncrypt from "../../function/params";
import useDataUpdate from "../../function/useDataUpdate";


const LeftDetail = ({ zoneId, rubriqueId }) => {
    const [zoneData, setZoneData] = useState([]);

    useEffect(() => {
        const traiteZone = localStorage.getItem("tabTraiteZone");
        const parsedData = JSON.parse(traiteZone);

        setZoneData(parsedData[zoneId]["article"]);
    }, [])

    const listArticles = useDataUpdate(zoneData);

    const filteredList = listArticles.filter((article) => article.rubriqueId == rubriqueId)

    const groupedArticles = [];

    for (let i = 0; i < filteredList.length; i += 2) {
        const pair = [filteredList[i], filteredList[i + 1]];
        groupedArticles.push(pair);
    }

    return (
        <>
            {
                groupedArticles.map((pair, index) => (
                    <div key={index}>
                        {pair.map((article, innerIndex) => (
                            <div key={innerIndex}>
                                {
                                    innerIndex === 0
                                        ? <section className="about about-1" id="about-1">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-lg-5">
                                                        <div className="about-img">
                                                            <div className="about-img-holder bg-overlay bg-section" style={{ backgroundImage: `url('https://chevea.coo.tg/${article.urlImage}')` }}>
                                                            </div>
                                                            <div>
                                                                <div className="counter">
                                                                    <div className="counter-icon"> <i className="flaticon-033-plug"></i></div>
                                                                    <div className="counter-num"> <span className="counting" data-counterup-nums="954">954</span>
                                                                        <p></p>
                                                                    </div>
                                                                    <div className="counter-name">
                                                                        <h6>happy clients</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-7">
                                                        <div className="heading heading-1">
                                                            {/* <p className="heading-subtitle heading-subtitle-bg">{article.titre}</p> */}
                                                            <h2 className="heading-title">{article.titre}</h2>
                                                        </div>
                                                        <div className="about-block">
                                                            <div className="row">
                                                                <div className="col-12 col-lg-7">
                                                                    <div className="block-left">
                                                                        <p className="paragraph" >{article.contenuArticle}</p><Link to={`/articles/${articleIdEncrypt(zoneId, rubriqueId, article.id)}`} className="btn btn--secondary" href="page-about.html">Détails <i className="energia-arrow-right"></i></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        : <section className="about about-3" id="about-3">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6">
                                                        <div className="heading heading-12">
                                                            {/* <p className="heading-subtitle">{article.titre}</p> */}
                                                            <h2 className="heading-title">{article.titre}</h2>
                                                        </div>
                                                        <div className="about-block">
                                                            <div className="block-left">
                                                                <p className="paragraph">{article.contenuArticle}</p>
                                                                <div className="signature-block"><Link className="btn btn--secondary" to={`/articles/${articleIdEncrypt(zoneId, rubriqueId, article.id)}`} >Détails <i className="energia-arrow-right"></i></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-5 offset-lg-1">
                                                        <div className="about-img">
                                                            <div className="about-img-holder bg-overlay bg-section" style={{ backgroundImage: `url('https://chevea.coo.tg/${article.urlImage}')` }}>
                                                            </div>
                                                            <div className="counter counter-4">
                                                                <div className="counter-num"> <span className="counting" data-counterup-nums="25">25</span>
                                                                    <p>+</p>
                                                                    <h6>years of experience</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                }
                            </div>
                        ))}
                    </div>
                ))
            }
        </>
    )
}

export default LeftDetail;