// Partie statique de 3 caractères de chaque côté de l'ID
const staticPart = '0c';

function generateRandomIdWithStaticPart(id, rid) {
    // Fonction pour générer une chaîne aléatoire de longueur donnée
    const generateRandomString = (length) => {
        const characters = 'abdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
        let randomString = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    };

    // Générer la chaîne aléatoire de 5 caractères
    const randomPart = generateRandomString(5);
    const secondPart = generateRandomString(5);

    // Concaténer la partie statique, la partie aléatoire, et l'ID
    const combinedString = `${randomPart}${staticPart}${id}${staticPart}${rid}${staticPart}${secondPart}`;

    return combinedString;
}

export default generateRandomIdWithStaticPart;