import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ArticlePage from './components/pages/article';
import Home from "./components/pages/home";
import DetailsRubrique from './components/pages/rubrique';

function App() {
  return (
    <Router basename='/'>
      <Routes>
          <Route exact path='/' element={<Home/>}></Route>
          <Route exact path='/details/:id' element={<DetailsRubrique/>}></Route>
          <Route exact path='/articles/:id' element={<ArticlePage/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
