import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

const RFeatures = () => {
    return (
        <>
            <section className="features features-1 bg-overlay bg-overlay-theme2 bg-section" id="features-1" style={{ backgroundImage: `url('assets/images/background/2.jpg')` }}>
                <div className="container">
                    <div className="heading heading-2 heading-light heading-light2">
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <p className="heading-subtitle">Sustainable, Reliable & Affordable Energy!</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <h2 className="heading-title">Providing Value To Our ClientsThrough Ongoing Product & Innovation.</h2>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1">
                                <p className="heading-desc">While improving the yield and performance of solar energy products, our PV industry experience enables us to provide in-depth material sourcing, financing and supply chain expertise for every step.</p>
                                <p className="heading-desc">Raw polycrystalline silicon for PV manufacturing. Offered in various grades and formats including chunks, chips, powder and ingot.</p>
                                <div className="actions-holder"><a className="btn btn--primary btn--inversed" href="page-contact.html">
                                    get started<i className="energia-arrow-right"></i></a><a className="btn btn--bordered btn--white" href="page-about.html">explore our plans</a></div>
                            </div>
                        </div>
                    </div>
                    <OwlCarousel className="carousel owl-carousel carousel-dots carousel-navs" items={4} autoplay={true} nav={false} dots={true} margin={25} loop={true} speed={800}>
                        <>
                            <div>
                                <div className="feature-panel-holder" data-hover="">
                                    <div className="feature-panel">
                                        <div className="feature-icon"><i className="flaticon-024-energy"></i></div>
                                        <div className="feature-content">
                                            <h4>Save Your Money</h4>
                                            <p>Save money on utilities or increase the value of your home by installing solar panels as a great option.</p>
                                        </div><a href="page-about.html"><i className="energia-arrow-right"></i> <span>explore more</span> </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="feature-panel-holder" data-hover="">
                                    <div className="feature-panel">
                                        <div className="feature-icon"><i className="flaticon-028-greenhouse"></i></div>
                                        <div className="feature-content">
                                            <h4>Home Is Energy</h4>
                                            <p>Everyday the sun provides us with abundance of free energy by placing solar panels on your roof.</p>
                                        </div><a href="page-about.html"><i className="energia-arrow-right"></i> <span>explore more</span> </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="feature-panel-holder" data-hover="">
                                    <div className="feature-panel">
                                        <div className="feature-icon"><i className="flaticon-026-world"></i></div>
                                        <div className="feature-content">
                                            <h4>Consult &amp; Planning</h4>
                                            <p>Our remote industrial solar systems are designed to reliably power our clients critical.</p>
                                        </div><a href="page-about.html"><i className="energia-arrow-right"></i> <span>explore more</span> </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="feature-panel-holder" data-hover="">
                                    <div className="feature-panel">
                                        <div className="feature-icon"><i className="flaticon-008-plant"></i></div>
                                        <div className="feature-content">
                                            <h4>Certified Engineers</h4>
                                            <p>Our sales engineers on our staff have experience and can design any complete solar system.</p>
                                        </div><a href="page-about.html"><i className="energia-arrow-right"></i> <span>explore more</span> </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="feature-panel-holder" data-hover="">
                                    <div className="feature-panel">
                                        <div className="feature-icon"><i className="flaticon-024-energy"></i></div>
                                        <div className="feature-content">
                                            <h4>Save Your Money</h4>
                                            <p>Save money on utilities or increase the value of your home by installing solar panels as a great option.</p>
                                        </div><a href="page-about.html"><i className="energia-arrow-right"></i> <span>explore more</span> </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="feature-panel-holder" data-hover="">
                                    <div className="feature-panel">
                                        <div className="feature-icon"><i className="flaticon-028-greenhouse"></i></div>
                                        <div className="feature-content">
                                            <h4>Home Is Energy</h4>
                                            <p>Everyday the sun provides us with abundance of free energy by placing solar panels on your roof.</p>
                                        </div><a href="page-about.html"><i className="energia-arrow-right"></i> <span>explore more</span> </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    </OwlCarousel>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="more-features">
                                <p>If you have any questions or need help, feel free to contact with our team, or you can call us any time <a href="tel:01061245741">(002) 01061245741</a></p><a className="btn btn--bordered btn--white" href="page-about.html">
                                    free estimate<i className="energia-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="video video-1 bg-overlay bg-overlay-video bg-section" id="video-1" style={{ backgroundImage: `url('assets/images/video/1.jpg')` }}>
                                <a className="popup-video btn-video" href="https://www.youtube.com/watch?v=nrJtHemSPW4"> <i className="fas fa-play"></i><span>watch our video!</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RFeatures;