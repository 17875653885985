import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';

const CasUtilisateur = ({ zoneData, zone }) => {
    const [sousRubriques, setSousRubrique] = useState([]);
    //const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (zoneData && zoneData["rubrique"]) {
            setSousRubrique(zoneData["rubrique"]);
            //setArticles(zoneData["article"]);
        }
    }, [zoneData])

    return (
        <>
            <section className="projects projects-grid" id="projects-grid-1">
                <div className="container">
                    <div className="heading heading-8">
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <h2 className="heading-title">{zone["titreZone"]}</h2>
                            </div>
                        </div>
                    </div>

                </div>
                <OwlCarousel className="carousel owl-carousel carousel-dots carousel-navs" items={4} autoplay={true} nav={true} dots={true} margin={30} loop={true} speed={800} center={true}>
                    {
                        sousRubriques.map((sr, index) =>
                            <div key={index}>
                                <div className="project-panel" data-hover="">
                                    <div className="project-panel-holder">
                                        <div className="project-img"><a className="link" href="projects-single.html"></a><img src="assets/images/projects/grid-carousel/1.jpg" alt="project image" /></div>

                                        <div className="project-content">
                                            <div className="project-title">
                                                <h4>
                                                    <a href="projects-single.html">
                                                        {sr.designationRubrique}
                                                    </a>
                                                </h4>
                                            </div>
                                            <div className="project-cat" dangerouslySetInnerHTML={{ __html: sr.descriptionRubrique }}>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    }
                </OwlCarousel>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="projects-load-more"> <a className="btn btn--secondary" href="projects-grid.html">explore all projects <i className="energia-arrow-right"></i></a></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CasUtilisateur;