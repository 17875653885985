const ABanniere = () => {
    return (
        <>
            <div class="module-content module-search-warp">
                <div class="pos-vertical-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                                <form class="form-search">
                                    <input class="form-control" type="text" placeholder="type words then enter" />
                                    <button></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div><a class="module-cancel" href="#"><i class="fas fa-times"></i></a>
            </div>
            <section class="page-title page-title-13" id="page-title">
                <div class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section" style={{ backgroundImage: `url('assets/images/page-titles/13.jpg')` }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-6 offset-lg-3">
                                <div class="title text-center">
                                    <h1 class="title-heading">our blog</h1>
                                    <ol class="breadcrumb breadcrumb-light d-flex justify-content-center">
                                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Blog</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ABanniere;