import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import generateRandomIdWithStaticPart from "../../function/idEncryption";
import useDataUpdate from '../../function/useDataUpdate';

const Features = ({ zoneData, zone }) => {
    const [currentFilter, setCurrentFilter] = useState(0);
    const [sousRubriques, setSousRubrique] = useState([]);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (zoneData && zoneData["rubrique"]) {
            if (zoneData["rubrique"].length > 0) {
                setCurrentFilter(zoneData["rubrique"][0].id);
                setSousRubrique(zoneData["rubrique"]);
                setArticles(zoneData["article"]);
            } else {
                setSousRubrique(zoneData["rubrique"]);
                setArticles(zoneData["article"]);
            }
        }
    }, [zoneData])

    const handleFilterChange = (filter) => {
        setCurrentFilter(filter);
    }

    return (
        <>
            <section className="features-bar bg-overlay bg-overlay-theme3" id="featuresBar-1">
                <div className="bg-section"> <img style={{ backgroundImage: `url('assets/images/sliders/3.jpg')` }} alt="background" /></div>
                <div className="">
                    <SousRubriqueComponent onFilterChange={handleFilterChange} rubriqueList={sousRubriques} />
                    <ArticleDisplay filter={currentFilter} articleList={articles} zoneId={zone.id} />
                </div>
            </section>
        </>
    )
}

const chunkArray = (array, size) => {
    return Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
        array.slice(i * size, i * size + size)
    );
};

const SousRubriqueComponent = ({ onFilterChange, rubriqueList }) => {
    const list = useDataUpdate(rubriqueList);

    const handleFilterClick = (filter) => {
        onFilterChange(filter)
    };

    return (
        <div className="row g-0 features-holder projects-filter" >
            {
                list.map((sr) =>
                    <div key={sr.id} className="col-12 col-sm-6 col-md-4 col-lg-2">
                        <div className="feature-panel">
                            <div className="feature-content">
                                <a onClick={() => handleFilterClick(sr.id)}>
                                    <i className="flaticon-024-energy"></i>
                                    <h5>{sr.designationRubrique}</h5>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

const ArticleDisplay = ({ filter, articleList, zoneId }) => {
    const navigate = useNavigate();
    const list = useDataUpdate(articleList)
    const imageUrl = "assets/images/about/1.jpg";

    const filteredProjects = filter
        ? list.filter((article) => article.rubriqueId === filter)
        : list

    const chunkedArticles = chunkArray(filteredProjects, 3);

    return (
        <div className="row" id="projects-all">
            <div className="col-12 project-item">
                <section className="about about-1" id="about-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <div className="about-img">
                                    <div className="about-img-holder bg-overlay bg-section" style={{ backgroundImage: `url(${imageUrl})` }}>
                                    </div>
                                    <div>
                                        <div className="counter">
                                            <div className="counter-icon"> <i className="flaticon-033-plug"></i></div>
                                            <div className="counter-num"> <span className="counting" data-counterup-nums="954">954</span>
                                                <p></p>
                                            </div>
                                            <div className="counter-name">
                                                <h6>happy clients</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-8">
                                <div className="heading heading-1">
                                    <h2 className="heading-title"></h2>
                                </div>
                                <div className="about-block">
                                    <section className="faqs faqs-2" id="faqs-1">
                                        <div className="container">
                                            <div className="accordion accordion-2" id="accordion03">
                                                <div className="row">
                                                    {chunkedArticles.map((column, columnIndex) => (
                                                        <div key={columnIndex} className="col-12 col-lg-6">
                                                            {column.map(article => (
                                                                <div key={article.id}>
                                                                    <div className="card">
                                                                        <div className="card-heading"><a className="card-link collapsed" data-hover="" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls={`collapse-${article.id}`} href={`#collapse-${article.id}`}>{article.titre}</a></div>
                                                                        <div className="collapse" id={`collapse-${article.id}`} data-bs-parent="#accordion03">
                                                                            <div className="card-body">{article.resumeArticle}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                    <div className="slide-action"><a className="btn btn--primary" onClick={() => navigate(`/details/${generateRandomIdWithStaticPart(zoneId, filter)}`)}> <span>Voir tout</span><i className="energia-arrow-right"></i></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Features;