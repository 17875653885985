import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from 'react-router-dom';
import urlBase from '../../config';
import useDataUpdate from '../../function/useDataUpdate';

const Banniere = ({ zoneData, zone }) => {
    const articles = useDataUpdate(zoneData["article"]);
    let url = urlBase;

    const navigate = useNavigate();

    return (
        <>
            <div className="module-content module-search-warp">
                <div className="pos-vertical-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                                <form className="form-search">
                                    <input className="form-control" type="text" placeholder="type words then enter" />
                                    <button></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div><a className="module-cancel" href="#"><i className="fas fa-times"></i></a>
            </div>
            <section className="slider slider-2" id="slider-2">
                <div className="container-fluid pe-0 ps-0">
                    <OwlCarousel className="slider-carousel owl-carousel carousel-navs carousel-dots" items={1} autoplay={true} nav={true} dots={true} loop={true} speed={800}>
                        {
                            articles.map((article, index) => (
                                <div key={index} className="slide bg-overlay bg-overlay-dark-slider-2" style={{ backgroundImage: `url('https://chevea.coo.tg/${article.urlImage}')` }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-7">
                                                <div className="slide-content">
                                                    <h1 className="slide-headline">{article.titre}</h1>
                                                    <p className="slide-desc">{article.resumeArticle}</p>
                                                    <div className="slide-action"><a className="btn btn--primary" onClick={() => navigate('/details/')}> <span>our services</span><i className="energia-arrow-right"></i></a><a className="btn btn--white justify-content-center" >more about us!</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </OwlCarousel>
                </div>
            </section>
        </>

    )
}

export default Banniere;