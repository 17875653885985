import { useEffect, useState } from "react";
const Pub = ({ zoneData, zone }) => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (zoneData && zoneData["article"]) {
            setArticles(zoneData["article"]);
        }
    }, [zoneData])

    return (
        <div className="cta-holder">
            <div className="container">
                <div className="row">
                    {
                        articles.map((art, index) => (
                            <div key={index} className="col-12">
                                <div className="cta">
                                    <div className="cta-img"> <img src={`https://chevea.coo.tg/${art.urlImage}`} alt="image" />
                                        <div className="cta-icon"> <i className="energia-phone-Icon"></i></div>
                                    </div>
                                    <div className="cta-body">
                                        <h5>{art.titre}</h5>
                                        <div className="cta-content">
                                            <p>{art.contenuArticle}</p>
                                            <a className="btn btn--bordered btn--white" href="request-quote.html">free estimate <i className="energia-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="more-actions">
                            <p>Sustainable, reliable & affordable energy systems, <a href="page-services.html">Find Your Solution Now! </a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pub;