import { useEffect, useState } from "react";

const Actualite = ({ zoneData, zone }) => {
    const [sousRubriques, setSousRubrique] = useState([]);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (zoneData && zoneData["rubrique"]) {
            setSousRubrique(zoneData["rubrique"]);
            setArticles(zoneData["article"]);
        }
    }, [zoneData])

    return (
        <>
            <section className="blog blog-2 blog-grid" id="blog-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-3">
                            <div className="heading heading-11 text-center">
                                <p className="heading-subtitle">news & Announcements</p>
                                <h2 className="heading-title">
                                    {sousRubriques.map((s) => s.designationRubrique)}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            articles.map((art) => (
                                <div key={art.id} className="col-12 col-lg-4">
                                    <div className="blog-entry" data-hover="">
                                        <div className="entry-content">
                                            <div className="entry-meta">
                                                <div className="entry-date"><span className="day">jan 21</span><span className="year">2021</span></div>

                                                <div className="entry-author">
                                                    <p>mike dolley</p>
                                                </div>
                                            </div>
                                            <div className="entry-title">
                                                <h4><a href="blog-single.html">{art.titre}</a></h4>
                                            </div>
                                            <div className="entry-img-wrap">
                                                <div className="entry-img"><a href="blog-single.html"><img src="assets/images/blog/grid/1.jpg" alt="Filing Solar Power Permits in 2020? Consider Following Important Factors" /></a></div>
                                                <div className="entry-category"><a href="blog-grid.html">solar</a><a href="blog-grid.html">insights</a>
                                                </div>
                                            </div>

                                            <div className="entry-bio">
                                                <p>{art.resumeArticle}</p>
                                            </div>
                                            <div className="entry-more"> <a className="btn btn--white btn-bordered" href="blog-single.html">read more <i className="energia-arrow-right"></i></a></div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Actualite;