import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../footer/footer";
import HomeHeader from "../homecomponents/homeheader";
import LeftDetail from '../rubriquecomponents/leftdetail';
import RBanniere from '../rubriquecomponents/rubriqueBanniere1';
import RFeatures from '../rubriquecomponents/rubriqueFeatures';

const DetailsRubrique = () => {
    const [listeMenu, setListMenu] = useState([])
    const [info, setInfo] = useState([])

    const { id } = useParams();
    const extractedId = id.split('0c')[1];
    const extractedRId = id.split('0c')[2];

    useEffect(() => {
        setListMenu(JSON.parse(localStorage.getItem("listeMenu")))
        setInfo(JSON.parse(localStorage.getItem("infoAbonne")))
    }, [])

    return (
        <>
            <div className="wrapper clearfix" id="wrapperParallax">
                <HomeHeader infoAbonne={info} menus={listeMenu} />
                <RBanniere id={extractedId} />
                <LeftDetail zoneId={extractedId} rubriqueId={extractedRId} />
                <RFeatures />
                <Footer />
            </div>
        </>
    )
}

export default DetailsRubrique;