import { useEffect, useState } from "react";

const useDataUpdate = (data) => {
    const [list, setList] = useState([]);

    useEffect(() => {
        if (data) {
            setList(data);
        }
    }, [data]);

    return list;
};

export default useDataUpdate;