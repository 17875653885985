import { useEffect, useState } from "react"
import Actualite from "../homecomponents/actualite"
import Banniere from "../homecomponents/banniere"
import CasUtilisateur from "../homecomponents/casutilisateur"
import Features from "../homecomponents/features"
import Pourquoi from "../homecomponents/pourquoi"
import Pub from "../homecomponents/pub"

const PageBuilder = ({ tabTraiteZone, zone }) => {
    const [zoneData, setZoneData] = useState({});

    useEffect(() => {
        setZoneData(tabTraiteZone[zone["id"]])
    }, [tabTraiteZone, zone])

    return (
        <>
            {
                zone["typeElement"] === 2 && zone["style"] === 1
                    ? <Banniere key={"ban"} zoneData={zoneData} zone={zone} />
                    : zone["typeElement"] === 3 && zone["style"] === 1
                        ? <Features key={"feat"} zoneData={zoneData} zone={zone} />
                        : zone["typeElement"] === 3 && zone["style"] === 2
                            ? <Pourquoi key={"pk"} zoneData={zoneData} zone={zone} />
                            : zone["typeElement"] === 3 && zone["style"] === 3
                                ? <CasUtilisateur key={"cas"} zoneData={zoneData} zone={zone} />
                                : zone["typeElement"] === 1 && zone["style"] === 1
                                    ? <Pub key={"pub"} zoneData={zoneData} zone={zone} />
                                    : <Actualite key={"actu"} zoneData={zoneData} zone={zone} /> // 2 && 2
            }
        </>
    )
}

export default PageBuilder; 