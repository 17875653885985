import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ABanniere from "../article/banniere";
import DetailArticle from "../article/detail";
import Footer from "../footer/footer";
import HomeHeader from "../homecomponents/homeheader";

const ArticlePage = () => {
    const [listeMenu, setListMenu] = useState([])
    const [info, setInfo] = useState([])

    const { id } = useParams();
    const extractedId = id.split('0c')[1];
    const extractedRId = id.split('0c')[2];
    const extractedAId = id.split('0c')[4];

    useEffect(() => {
        setListMenu(JSON.parse(localStorage.getItem("listeMenu")))
        setInfo(JSON.parse(localStorage.getItem("infoAbonne")))
    }, [])

    return (
        <>
            <div className="wrapper clearfix" id="wrapperParallax">
                <HomeHeader infoAbonne={info} menus={listeMenu} />
                <ABanniere />
                <DetailArticle zoneId={extractedId} rubriqueId={parseInt(extractedRId, 10)} articleId={parseInt(extractedAId, 10)} />
                <Footer />
            </div>
        </>
    )
}

export default ArticlePage