const Footer = () => {
    return (
        <footer className="footer footer-1">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                            <div className="footer-widget widget-links">
                                <div className="footer-widget-title">
                                    <h5>company</h5>
                                </div>
                                <div className="widget-content">
                                    <ul>
                                        <li><a href="page-about.html">About Us</a></li>
                                        <li><a href="page-team.html">Meet Our Team</a></li>
                                        <li><a href="blog-grid.html">News & Media</a></li>
                                        <li><a href="projects-standard.html">Our Projects</a></li>
                                        <li><a href="page-contact.html">contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                            <div className="footer-widget widget-links">
                                <div className="footer-widget-title">
                                    <h5>Services</h5>
                                </div>
                                <div className="widget-content">
                                    <ul>
                                        <li><a href="services-turbines.html">Solar Panels</a></li>
                                        <li><a href="services-turbines.html">Wind Turbines</a></li>
                                        <li><a href="services-turbines.html">Hydropower Plants</a></li>
                                        <li><a href="services-turbines.html">Fossil Resources</a></li>
                                        <li><a href="services-turbines.html">Battery Materials</a></li>
                                        <li><a href="services-turbines.html">biofuels</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-5">
                            <div className="footer-widget widget-links widget-icon">
                                <div className="footer-widget-title">
                                    <h5>support</h5>
                                </div>
                                <div className="widget-content">
                                    <ul>
                                        <li><a href="page-contact.html">Terms & Conditions</a></li>
                                        <li><a href="page-contact.html">Shipping Policy</a></li>
                                        <li><a href="page-contact.html">Delivery Tips</a></li>
                                        <li><a href="page-contact.html">Returns</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="footer-widget widget-contact">
                                <div className="widget-content">
                                    <ul>
                                        <li className="phone">+2<a href="tel:+01061245741"> 01061245741</a></li>
                                        <li className="email">Email: <a href="mailto:info@energia.com">info@energia.com</a></li>
                                        <li className="address">
                                            <p>2307 Beverley Rd Brooklyn, New York 11226 United States.</p>
                                        </li>
                                        <li className="directions"><a href="page-contact.html"><i className="energia-location-Icon"></i>get directions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-copyright">
                                <div className="copyright"><span>&copy; 2021 energia by  <a href="https://1.envato.market/kP9BV"> Zytheme.com</a>. all rights reserved. </span>
                                    <ul className="list-unstyled social-icons">
                                        <li> <a className="share-facebook"><i className="energia-facebook"></i>facebook </a></li>
                                        <li> <a className="share-twitter"><i className="energia-twitter"></i>twitter</a></li>
                                        <li> <a className="share-youtube"><i className="energia-youtube"></i>youtube</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;