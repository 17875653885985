import { useEffect, useState } from "react";
import Footer from "../footer/footer";
import HomeHeader from "../homecomponents/homeheader";
import PageBuilder from "./pagebuilder";

const Home = ({ }) => {
    const [listeZone, setListeZone] = useState([]);
    const [listeMenu, setListeMenu] = useState([]);
    const [traiteZone, setTraiteZone] = useState({});
    const [infoAbonne, setInfoAbonne] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://test.coo.tg/page/template/site/api', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "adresseWeb": "chevea.coo.tg",
                        "siPageAccueil": "1",
                        "pageId": 0
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();

                setListeZone(result["data"]['listeZone']);
                setTraiteZone(result["data"]['tabTraiteZone']);
                setListeMenu(result["data"]['listeMenu']);
                setInfoAbonne(result["data"]['infoAbonne']);
                setLoading(false)

                localStorage.setItem("listeZone", JSON.stringify(result["data"]['listeZone']));
                localStorage.setItem("tabTraiteZone", JSON.stringify(result["data"]['tabTraiteZone']));
                localStorage.setItem("listeMenu", JSON.stringify(result["data"]['listeMenu']));
                localStorage.setItem("infoAbonne", JSON.stringify(result["data"]['infoAbonne']));
            } catch (error) {
                setError(error)
                setLoading(false)
            }
        };

        fetchData();
    }, [])

    return (
        <>
            <div className="wrapper clearfix" id="wrapperParallax">
                <HomeHeader menus={listeMenu} infoAbonne={infoAbonne} />
                {
                    listeZone.map((zone, index) =>
                        <PageBuilder
                            key={index}
                            zone={zone}
                            tabTraiteZone={traiteZone}
                        />
                    )
                }
                <Footer />
            </div>
        </>

    )
}
export default Home;