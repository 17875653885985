import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

const Pourquoi = ({ zoneData, zone }) => {
    const [sousRubriques, setSousRubrique] = useState([]);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (zoneData && zoneData["rubrique"]) {
            setSousRubrique(zoneData["rubrique"]);
            setArticles(zoneData["article"]);
        }
    }, [zoneData])

    return (
        <>
            <section className="services services-2 bg-grey" id="services-2">
                <div className="container">
                    <div className="heading heading-2">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <h2 className="heading-title">{zone["titreZone"]}</h2>
                            </div>
                            <div className="col-12 col-lg-6">
                                <p className="heading-desc">Today, our company is one of the most important PV suppliers in the North America and Europe. Our sales offices and warehouses include PV industry experience enables us to provide in-depth material sourcing and supply chain expertise for every step of production and installation process.</p>
                                <div className="actions-holder"><a className="btn btn--primary" href="page-about.html">
                                    read more<i className="energia-arrow-right"></i></a><a className="btn btn--bordered btn--white" href="page-services.html">find your solution</a></div>
                            </div>
                        </div>

                    </div>

                    <OwlCarousel className="carousel owl-carousel carousel-dots" items={3} autoplay={true} nav={false} dots={true} margin={30} loop={true} speed={800}>
                        {
                            sousRubriques.map((sr) =>
                                <div key={sr.id}>
                                    <div className="service-panel services-panel-2">
                                        <div className="service-icon"><i className="flaticon-004-solar-panel"></i></div>
                                        <div className="service-content">
                                            <h4><a href="services-turbines.html">{sr.designationRubrique}</a></h4>
                                            <div dangerouslySetInnerHTML={{ __html: sr.descriptionRubrique }} />
                                            <ul className="list-unstyled advantages-list">
                                            </ul><a className="btn btn--secondary" href="services-turbines.html">read more <i className="energia-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </OwlCarousel>
                </div>
            </section>
        </>
    )
}

export default Pourquoi;